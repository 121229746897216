import React, { useContext, useRef, useState, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { InfinitySpin } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DataTable from "react-data-table-component";

const MySwal = withReactContent(Swal);

export default function Settings() {
    const { context, setContext } = useContext(AppContext);

    const [settings, setSettings] = useState([]);
    const txtNewValue = useRef();

    const columns = [
        {
            name: "Name",
            selector: row => row.settingType.name,
            sortable: true
        },
        {
            name: "Value",
            selector: row => row.value,
            sortable: true
        },
        {
            name: "Actions",
            selector: row => (<button className='btn btn-secondary' onClick={() => openModalChangeSetting(row.id, row.settingType.name, row.value, row.settingType.id)}><i className='fa fa-pencil'></i></button>)
        }
    ];

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: false, urlBotaoVoltar: null }));

        refreshSettings();
    }, []);

    function refreshSettings() {
        const token = context.token;

        fetch("/api/settings", { method: "GET", headers: { "Authorization": `Bearer ${token}`, } })
            .then(result => {

                if (result.status === 200) {
                    return result.json();
                }
                else {
                    throw new Error(`Error obtaining settings: ${JSON.stringify(result)}`);
                }
            })
            .then(result => {

                if (!result?.data) {
                    throw new Error(`Error obtaining settings: ${JSON.stringify(result)}`);
                }

                setSettings(result?.data);
            })
            .catch(err => {
                console.log(err);
                MySwal.fire({
                    icon: "error",
                    html: "Error obtaining settings"
                });

                return;
            })
            .finally(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }

    function openModalChangeSetting(idSetting, settingName, currentValue) {
        let html = (<div style={{ display: "flex", flexDirection: "column" }}>
            <h4><i className='fa fa-cog'></i>&nbsp;&nbsp;Change setting</h4><br />
            <label>Name:</label>
            <input className='form-control' disabled="disabled" value={settingName} />
            <br />
            <label>Current Value:</label>
            <input className='form-control' disabled="disabled" value={currentValue} />
            <br />
            <label>New Value:</label>
            <input className='form-control' type='text' ref={txtNewValue} />
            <br /><br />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button className='btn btn-primary' onClick={() => postSaveSetting(idSetting, false)} >Salvar</button>
            </div>
        </div>);

        MySwal.fire({
            html: html,
            showCancelButton: false,
            showConfirmButton: false
        });
    }

    function postSaveSetting(idSetting) {
        setContext(curr => ({ ...curr, isLoading: true }));

        MySwal.close();

        let error = "";

        if (!idSetting) {
            error += "Error retrieving the setting ID<br/>";
        }

        let newValue = txtNewValue?.current?.value;

        if (!newValue) {
            error += "The field \"New Value\" is necessary!";
        }

        if (error) {
            MySwal.fire({
                icon: "error",
                html: error
            });

            return;
        }

        fetch(`api/settings/${idSetting}`, {
            method: "POST",
            body: JSON.stringify({ value: newValue }),
            headers: { "Authorization": `Bearer ${context.token}`, "Content-Type": "application/json" }
        }).then(result => {
            if (result.status == 200) {
                refreshSettings();
            }
            else {
                throw new Error("Error updating the setting");
            }
        }).catch(() => {
            MySwal.fire({
                icon: "error",
                html: "Error updating the setting"
            });
        }).finally(() => {
            setContext(curr => ({ ...curr, isLoading: false }));
        });
    }

    return (
        <>
            <div style={{ width: "100%", height: "80vh", display: !context.isLoading ? "none" : "flex", justifyContent: "center", alignContent: "center" }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-cog"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Settings</h1>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row" style={{ display: "flex", justifyContent: "space-around" }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    columns={columns}
                                    data={settings}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
