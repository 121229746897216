import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { InfinitySpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

export default function Sales() {
    const { context, setContext } = useContext(AppContext);

    const params = useParams();

    const [show, setShow] = useState({ sales: [] });
    const [totalRows, setTotalRows] = useState(0);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [salesPerPage, setSalesPerPage] = useState(10);

    const columns = [
        {
            name: "Section",
            selector: row => row.section,
            sortable: true
        },
        {
            name: "Row",
            selector: row => row.row,
            sortable: true
        },
        {
            name: "Qty",
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: "Price",
            selector: row => row.price,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true
        }
    ];

    const expandedComponent = ({ data }) => {
        return (<table style={{ width: "100%" }}>
            <tbody>
                <tr style={{ backgroundColor: "#F3F3F3" }}>
                    <th style={{ padding: "20px 20px" }}>Notes: {data.notes}</th>
                </tr>
            </tbody>
        </table>);
    };

    async function fetchSales(page, newPerPage) {
        setIsLoadingTable(true);
        const response = await fetch(`/api/pricehistory/${params.id}?pageNumber=${page}&pageSize=${newPerPage}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${context.token}`
                }
            });

        if (response.status == 200) {
            const result = await response.json();

            setShow(result.show);
            setTotalRows(result.totalRows);
        }

        setIsLoadingTable(false);
        setContext(old => ({ ...old, isLoading: false }));
    }

    async function handleSalesPageChange(page) {
        if (!show?.sales?.length) return;

        await fetchSales(page, salesPerPage);
    }

    async function handleSalesRowsPerPageChange(newPerPage, page) {
        if (!show?.sales?.length) return;

        if (newPerPage != salesPerPage)
            setSalesPerPage(newPerPage);

        await fetchSales(page, newPerPage);
    }

    useEffect(() => {
        setContext(old => ({ ...old, urlBotaoVoltar: "/pricehistory", isLoading: true }));
        fetchSales(1, salesPerPage);
    }, []);

    return (
        <>
            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="row" style={{ width: "100%" }}>
                                <div className="col-lg-6 col-12" >
                                    <span><b>Headliner:</b> {show.headliner}</span><br /><br />
                                    <span><b>Day:</b> {show.day}</span><br /><br />
                                    <span><b>Date:</b> {show.date}</span><br /><br />
                                    <span><b>Time:</b> {show.time}</span><br /><br />
                                </div>
                                <div className="col-lg-6 col-12" >
                                    <span><b>Event Name:</b> {show.event}</span><br /><br />
                                    <span><b>Venue:</b> {show.venue}</span><br /><br />
                                    <span><b>City:</b> {show.city}</span><br /><br />
                                    <span><b>State:</b> {show.state}</span><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <h4>Sales</h4>
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={columns}
                                    data={show.sales ?? []}
                                    progressPending={isLoadingTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    pagination
                                    paginationServer
                                    expandableRows
                                    expandableRowsComponent={expandedComponent}
                                    noDataComponent={<>No rows found</>}
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handleSalesRowsPerPageChange}
                                    onChangePage={handleSalesPageChange}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationComponentOptions={{ rowsPerPageText: "Lines per page", rangeSeparatorText: "of" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

