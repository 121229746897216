import React, { createContext, useEffect, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [context, setContext] = useState(() => {
        const storedData = localStorage.getItem("sd");
        return storedData ? JSON.parse(storedData) : { isLoading: true };
    });

    useEffect(() => {
        localStorage.setItem("sd", JSON.stringify(context));
    }, [context]);

    return (
        <AppContext.Provider value={{ context, setContext }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
