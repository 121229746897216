import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { blue, brown, deepOrange, deepPurple, red } from "@mui/material/colors";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import logo from "../../img/tixtext.png";
import BotaoVoltar from "./BotaoVoltar";
import Protected from "../Protected/Protected";

export default function Layout({ childEl }) {
    const colors = [deepOrange[500], brown[500], deepPurple[500], red[500], blue[500]];

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const btnLogout = useRef();
    const prevOpen = useRef(open);

    const { context, setContext } = useContext(AppContext);

    const doLogout = async () => {
        setContext(curr => ({ ...curr, isLoading: true }));

        const result = await fetch("/api/auth/logout", { method: "POST", headers: { "Authorization": `Bearer ${context.token}` } });

        if (result.status === 200) {
            setContext(old => { return { ...old, token: null, username: null, profiles: [], isLoading: false }; });
            handleClose();
        } else {
            alert("Error logging out!");
            setContext(curr => ({ ...curr, isLoading: false }));
        }
    };

    const goToChangePassword = () => {
        window.location.href = "/password/reset";
        handleClose();
    };

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => anchorRef.current && anchorRef.current.contains(event.target) ? null : setOpen(false);

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    };

    const getRandomColor = () => {
        if (context.userIconColor)
            return context.userIconColor;

        var randomNum = Math.floor(Math.random() * (colors.length - 1));

        var randomColor = colors[randomNum];

        setContext(curr => ({ ...curr, userIconColor: randomColor }));

        return randomColor;
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (window.location.pathname == "/")
            setContext(curr => ({ ...curr, isLoading: false }));
    }, []);

    return (
        <>
            <div id="wrapper">

                <ul className="navbar-nav bg-gradient-secondary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                        <img src={logo} alt="TixText Logo" className="tixtextHeaderLogo" />
                    </a>

                    <hr className="sidebar-divider my-0" />

                    <Protected context={context} requiredRoles={["stagefront"]}>
                        <li className="nav-item active">
                            <a className="nav-link" href="/ticketmaster">
                                <i className="fa fa-ticket"></i>
                                <span>Ticketmaster Account Management</span></a>
                        </li>
                    </Protected>

                    <Protected context={context} requiredRoles={["external"]}>
                        <li className="nav-item active">
                            <a className="nav-link" href="/processes">
                                <i className="fa fa-network-wired"></i>
                                <span>Processes</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/sms/mailbox">
                                <i className="fa fa-envelope"></i>
                                <span>SMS Mailbox</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/settings">
                                <i className="fa fa-cog"></i>
                                <span>Settings</span></a>
                        </li>
                    </Protected>


                    <Protected context={context} requiredRoles={["administrator"]}>
                        <li className="nav-item active">
                            <a className="nav-link" href="/bas">
                                <i className="fas fa-fw fa-tachometer-alt"></i>
                                <span>BAS Tasks</span></a>
                        </li>

                        {/*<li className="nav-item active">
                            <a className="nav-link" href="/slots">
                                <i className="fa fa-toggle-on"></i>
                                <span>Slots Rotation</span></a>
                        </li>*/}

                        <li className="nav-item active">
                            <a className="nav-link" href="/users">
                                <i className="fa fa-user"></i>
                                <span>Users Slots</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/pricehistory">
                                <i className="fa fa-dollar-sign"></i>
                                <span>Price History</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/configuration">
                                <i className="fa fa-sliders"></i>
                                <span>BAS Parameters</span></a>
                        </li>
                    </Protected>
                </ul>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>

                            <ul className="navbar-nav d-flex w-100" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                <li className="nav-item dropdown no-arrow">
                                    <Stack direction="row" spacing={2} >
                                        {!context.urlBotaoVoltar ?
                                            <></> :
                                            <BotaoVoltar />
                                        }
                                    </Stack>
                                </li>
                                <li className="nav-item dropdown no-arrow">
                                    <Stack direction="row" spacing={2}>
                                        <div>
                                            <Button
                                                ref={anchorRef}
                                                id="composition-button"
                                                aria-controls={open ? "composition-menu" : undefined}
                                                aria-expanded={open ? "true" : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                            >
                                                <Avatar sx={{ bgcolor: getRandomColor(), width: 36, height: 36 }}>
                                                    {context?.username[0]}
                                                </Avatar>
                                            </Button>
                                            <Popper
                                                open={open}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === "bottom-start" ? "left top" : "left bottom",
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                <MenuList
                                                                    autoFocusItem={open}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                    onKeyDown={handleListKeyDown}
                                                                >
                                                                    <MenuItem onClick={() => goToChangePassword()} style={{ fontSize: "13px" }}>
                                                                        Change Password
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => doLogout()} ref={btnLogout} id="btnLogout" style={{ fontSize: "13px" }}>
                                                                        Logout
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </Stack>


                                </li>
                            </ul>
                        </nav>
                        <div className="container-fluid" >
                            {childEl}
                        </div>
                    </div>

                    <footer className='footer-layout'>
                        © TixText 2023 - All rights reserved
                    </footer>
                </div>
            </div>
        </>
    );
}
