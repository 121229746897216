import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { InfinitySpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);

export default function DetailsProcess() {
    const { context, setContext } = useContext(AppContext);
    const params = useParams();
    const [totalRows, setTotalRows] = useState(0);
    const [processRows, setProcessRows] = useState([]);
    const [processTypes, setProcessTypes] = useState([]);
    const [processData, setProcessData] = useState({ name: "", hasValidLines: false });

    const [perPage, setPerPage] = useState(10);
    const [isLoadingTable, setIsLoadingTable] = useState(true);

    const runTypeSlt = useRef(null);

    const columns = getColumnsByProcessType(params.processTypeId);

    const ExpandedComponent = ({ data }) => {

        switch (parseInt(params.processTypeId ?? "0")) {
            case 12: // MLB Lottery
                return (<table style={{ width: "100%" }}>
                    <tbody>
                        <>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={6} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>URL: <span style={{ fontWeight: "100" }}>{data.columnA}</span></th>
                                <th colSpan={6} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Proxy: <span style={{ fontWeight: "100" }}>{data.columnN}</span></th>
                            </tr>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>First: <span style={{ fontWeight: "100" }}>{data.columnB}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Last: <span style={{ fontWeight: "100" }}>{data.columnC}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>ZipCode: <span style={{ fontWeight: "100" }}>{data.columnI}</span></th>
                            </tr>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Birth Month: <span style={{ fontWeight: "100" }}>{data.columnK}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Birth Day: <span style={{ fontWeight: "100" }}>{data.columnL}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Birth Year: <span style={{ fontWeight: "100" }}>{data.columnM}</span></th>
                            </tr>

                            {!data.isValid ?
                                (<tr style={{ backgroundColor: "#F3F3F3" }}>
                                    <th colSpan={12} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Error: <span style={{ fontWeight: "100" }}>{data.errorMsg}</span></th>
                                </tr>) :
                                <></>}
                        </>
                    </tbody>
                </table>);
            case 7: // TM Creator / VF Submitter
                return (<table style={{ width: "100%" }}>
                    <tbody>
                        <>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={6} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>URL: <span style={{ fontWeight: "100" }}>{data.columnH}</span></th>
                                <th colSpan={6} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Proxy: <span style={{ fontWeight: "100" }}>{data.columnX}</span></th>
                            </tr>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>First: <span style={{ fontWeight: "100" }}>{data.columnC}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Last: <span style={{ fontWeight: "100" }}>{data.columnD}</span></th>
                                <th colSpan={4} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>ZipCode: <span style={{ fontWeight: "100" }}>{data.columnE}</span></th>
                            </tr>
                            <tr style={{ backgroundColor: "#F3F3F3", color: !data.isValid ? "red" : "black" }}>
                                <th colSpan={2} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Show 1: <span style={{ fontWeight: "100" }}>{data.columnI}</span></th>
                                <th colSpan={2} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Show 2: <span style={{ fontWeight: "100" }}>{data.columnJ}</span></th>
                                <th colSpan={2} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Show 3: <span style={{ fontWeight: "100" }}>{data.columnK}</span></th>
                                <th colSpan={2} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Show 4: <span style={{ fontWeight: "100" }}>{data.columnL}</span></th>
                                <th colSpan={2} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Show 5: <span style={{ fontWeight: "100" }}>{data.columnM}</span></th>
                            </tr>

                            {!data.isValid ?
                                (<tr style={{ backgroundColor: "#F3F3F3" }}>
                                    <th colSpan={12} style={{ padding: "20px 20px", color: !data.isValid ? "red" : "black" }}>Error: <span style={{ fontWeight: "100" }}>{data.errorMsg}</span></th>
                                </tr>) :
                                <></>}
                        </>

                        {[4].includes(data.tmCreatorRun?.processRunStatusId) ?
                            (<tr style={{ backgroundColor: "#F3F3F3" }}>
                                <th colSpan={12} style={{ padding: "20px 20px", color: "black" }}>TM Creator Result: <span style={{ fontWeight: "100" }}>{data.tmCreatorRun.resultMessage}</span></th>
                            </tr>) :
                            <></>}

                        {[4].includes(data.vfSubmitterRun?.processRunStatusId) ?
                            (<tr style={{ backgroundColor: "#F3F3F3" }}>
                                <th colSpan={12} style={{ padding: "20px 20px", color: "black" }}>VF Submitter Result: <span style={{ fontWeight: "100" }}>{data.vfSubmitterRun.resultMessage}</span></th>
                            </tr>) :
                            <></>}

                        {[4].includes(data.tmCreatorVfSubmitterRun?.processRunStatusId) ?
                            (<tr style={{ backgroundColor: "#F3F3F3" }}>
                                <th colSpan={12} style={{ padding: "20px 20px", color: "black" }}>TM + VF Result: <span style={{ fontWeight: "100" }}>{data.tmCreatorVfSubmitterRun.resultMessage}</span></th>
                            </tr>) :
                            <></>}
                    </tbody>
                </table>);
            default: break;
        }

    };

    async function fetchProcessRows(page, perPage) {
        setIsLoadingTable(true);

        const result = await fetch(`/api/processes/${params.processId}?pageNumber=${page}&pageSize=${perPage}`,
            {
                method: "GET",
                headers:
                {
                    "Authorization": `Bearer ${context.token}`
                }
            });

        if (result.status == 200) {
            const data = await result.json();

            setProcessRows(data.lines);
            setProcessTypes(data.processSubTypes);
            setTotalRows(data.totalRows);

            var hasValidLines = (data.lines ?? []).some(i => i.isValid === true);

            setProcessData({ name: data.processName, hasValidLines });
        }

        setIsLoadingTable(false);
    }

    async function startSingleRun(processId) {
        let html = (<>
            <label>Run Type:</label><br />
            <select ref={runTypeSlt} className="form-control">
                <option value="">Select...</option>
                {processTypes.map(x => {
                    return <option key={x.id} value={x.id}>{x.description}</option>;
                })}
            </select>
        </>);

        try {
            const value = await MySwal.fire({
                html: html,
                confirmButtonText: "Start Process",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                cancelButtonColor: "#e74a3b",
                confirmButtonColor: "#37b620",
                reverseButtons: true
            });

            if (!value.value) return;

            setContext(curr => ({ ...curr, isLoading: true }));

            let runType = runTypeSlt.current.value;

            if (!runType) throw new Error("Run Type must be selected");

            const processItem = processRows.find(i => i.id == processId);

            if (!processItem?.isValid) throw new Error("Invalid row");

            const hasProcessBlockStatus = (!!processRows?.lastRun && ![4, 6].includes(processRows?.lastRun?.processRunStatusId));

            if (hasProcessBlockStatus) throw new Error("There is a process of same type running or finished for this line");

            const result = await fetch("/api/processes/single",
                {
                    method: "POST",
                    headers: { "Authorization": `Bearer ${context.token}`, "Content-Type": "application/json" },
                    body: JSON.stringify({ processDataId: processId, processTypeId: runType })
                });

            if (result.status != 200) throw new Error("Fail to insert row");

            const response = await result.json();

            if (!response?.success) throw new Error("Fail to insert row");

            await fetchProcessRows();

            MySwal.fire({
                icon: "success",
                html: "Task added successfully to the processes queue!"
            });
        } catch (ex) {
            Swal.fire({
                icon: "error",
                html: ex.message ?? ""
            });
        } finally {
            setContext(curr => ({ ...curr, isLoading: false }));
        }
    }

    async function runBulkProcess() {
        let html = <>
            <label>Which process do you want to run?</label><br />
            <select className="form-control" ref={runTypeSlt}>
                <option value="">Select...</option>
                {processTypes.map(x => {
                    return <option key={x.id} value={x.id}>{x.description}</option>;
                })}
            </select>
        </>;

        const value = await MySwal.fire({
            html: html,
            confirmButtonText: "Confirm",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            cancelButtonColor: "#e74a3b",
            confirmButtonColor: "#37b620",
            reverseButtons: true
        });

        try {
            if (!value.value) return;

            setContext(curr => ({ ...curr, isLoading: true }));

            let runTypeId = runTypeSlt.current.value;

            if (!runTypeId) throw new Error("Process Type must be selected");

            var requestBody = {
                processId: params.processId,
                processRunTypeId: runTypeId
            };

            let result = await fetch("/api/processes/bulk",
                {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${context.token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody)
                });


            if (result.status != 200) throw new Error("Fail to insert rows");

            let response = await result.json();

            if (!response?.success) throw new Error("Fail to insert rows");

            await fetchProcessRows();

            MySwal.fire({
                icon: "success",
                html: "Tasks added successfully to the processes queue!"
            });
        } catch (ex) {
            Swal.fire({
                icon: "error",
                html: ex.message ?? ""
            });
        } finally {
            setContext(curr => ({ ...curr, isLoading: false }));
        }
    }

    function getColumnsByProcessType(processTypeId) {
        function buildColumn(name, selector, grow, sortable, sortFunction, minWidth, center) {
            let columnItem = {
                name: name,
                selector: selector,
                grow: grow,
                sortable: sortable === true
            };

            if (sortFunction) {
                columnItem.sortFunction = sortFunction;
            }

            if (minWidth) {
                columnItem.minWidth = minWidth;
            }

            if (center) {
                columnItem.center = center;
            }

            return columnItem;
        }

        function buildDefaultColumns(emailColumnName, passwordColumnName, phoneColumnName) {
            return [
                {
                    name: "Email",
                    selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row[emailColumnName]}</span>,
                    grow: 3,
                    sortable: true
                },
                {
                    name: "Password",
                    selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row[passwordColumnName]}</span>,
                    grow: 1,
                    sortable: true
                },
                {
                    name: "Phone",
                    selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row[phoneColumnName]}</span>,
                    grow: 1,
                    sortable: true
                }
            ];
        }

        let columns = buildDefaultColumns("columnA", "columnB", "columnF");

        let addDefaultStatusColumn = true, statusColumnLabel = "Status";

        switch (parseInt(processTypeId ?? "0")) {
            case 7:
                addDefaultStatusColumn = false;

                columns.push(buildColumn("TM Status",
                    row => <span style={{ color: !row.isValid ? "red" : "black" }}>{!row.tmCreatorRun ? "N/R" : row.tmCreatorRun.processRunStatusDescription}</span>,
                    1,
                    true,
                    (a, b) => Intl.Collator().compare(a.tmCreatorRun?.processRunStatusDescription, b.tmCreatorRun?.processRunStatusDescription)
                ));

                columns.push(buildColumn("VF Status",
                    row => <span style={{ color: !row.isValid ? "red" : "black" }}>{!row.vfSubmitterRun ? "N/R" : row.vfSubmitterRun.processRunStatusDescription}</span>,
                    1,
                    true,
                    (a, b) => Intl.Collator().compare(a.vfSubmitterRun?.processRunStatusDescription, b.vfSubmitterRun?.processRunStatusDescription)
                ));

                columns.push(buildColumn("TM/VF Status",
                    row => <span style={{ color: !row.isValid ? "red" : "black" }}>{!row.tmCreatorVfSubmitterRun ? "N/R" : row.tmCreatorVfSubmitterRun.processRunStatusDescription}</span>,
                    1,
                    true,
                    (a, b) => Intl.Collator().compare(a.tmCreatorVfSubmitterRun?.processRunStatusDescription, b.tmCreatorVfSubmitterRun?.processRunStatusDescription)
                ));
                break;
            case 11:
                columns = [
                    {
                        name: "Current Email",
                        selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row.columnA}</span>,
                        sortable: true
                    },
                    {
                        name: "Password",
                        selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row.columnB}</span>,
                        sortable: true
                    },
                    {
                        name: "New Email",
                        selector: row => <span style={{ color: !row.isValid ? "red" : "black" }}>{row.columnG}</span>,
                        sortable: true
                    }
                ];
                break;
            case 12:
                columns = buildDefaultColumns("columnD", "columnE", "columnJ");
                break;
            default: break;
        }

        if (addDefaultStatusColumn) {
            columns.push(buildColumn(statusColumnLabel,
                row => <span style={{ color: !row.isValid ? "red" : "black" }}>{!row.lastRun ? "N/R" : row.lastRun.processRunStatusDescription}</span>,
                1,
                true,
                (a, b) => Intl.Collator().compare(a.lastRun?.processRunStatusDescription, b.lastRun?.processRunStatusDescription)
            ));
        }

        columns.push(buildColumn("Actions",
            row => {
                if (row.isValid && (!row.lastRun || [4, 6].includes(row.lastRun?.processRunStatusId))) {
                    return (<button className="btn btn-primary" onClick={() => startSingleRun(row.id)}>Single Run</button>);
                }
            },
            null,
            false,
            null,
            "150px",
            true
        ));

        return columns;
    }

    async function handlePageChange(page) {
        if (!processRows?.length && !processTypes?.length) {
            return;
        }

        await fetchProcessRows(page, perPage);
    }

    async function handleRowsPerPageChange(newPerPage, page) {
        if (!processRows?.length && !processTypes?.length) {
            return;
        }

        if (newPerPage != perPage) {
            setPerPage(newPerPage);
        }

        await fetchProcessRows(page, newPerPage);
    }

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: true, urlBotaoVoltar: null }));

        Promise.allSettled([fetchProcessRows(1, perPage)])
            .then(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }, []);

    return (
        <>
            <div className="d-sm-flex align-items-center mb-4">
                {!processRows.length ? <></> :
                    <>
                        <i className="fa fa-network-wired"></i>&nbsp;&nbsp;
                        <h1 className="h3 mb-0 text-gray-800">Process: {processData.name} ({params.processId})</h1>
                    </>}
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">

                            <div className="row" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row", padding: "20px" }}>
                                <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                                    {processData.hasValidLines ?
                                        <button onClick={() => runBulkProcess()} className="btn btn-primary"><i className="fa fa-plus"></i> Bulk Process</button> :
                                        <></>
                                    }
                                </div>
                                <div className="col-9" style={{ display: "flex", justifyContent: "center" }}>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={columns}
                                    data={processRows}
                                    pagination
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    expandableRows
                                    expandableRowsComponent={data => ExpandedComponent(data, params.processTypeId)}
                                    progressPending={isLoadingTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    paginationServer
                                    noDataComponent={<>No rows found</>}
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={{ rowsPerPageText: "Lines per page", rangeSeparatorText: "of" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

