import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

export default function BotaoVoltar() {
    const { context } = useContext(AppContext);

    function redirectToUrl() {
        window.location.href = context.urlBotaoVoltar;
    }

    return <button className="btn btn-primary" onClick={() => redirectToUrl()}><i className="fa fa-arrow-left"></i> Voltar</button>;
}
