import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);


export default function Processes() {
    const { context, setContext } = useContext(AppContext);

    // Processes
    const [processesPerPage, setProcessesPerPage] = useState(10);
    const [isLoadingProcessesTable, setIsLoadingProcessesTable] = useState(true);
    const [processesInfo, setProcessesInfo] = useState({});
    const [processTypes, setProcessTypes] = useState([]);

    // Tasks
    const [tasksPerPage, setTasksPerPage] = useState(10);
    const [isLoadingTasksTable, setIsLoadingTasksTable] = useState(true);
    const [tasksInfo, setTasksInfo] = useState({});

    const [csvData, setCsvData] = useState([]);

    const navigate = useNavigate();

    const processTypeSelect = useRef(null);
    const csvButton = useRef(null);

    const columnsProcesses = [
        {
            name: "Name",
            selector: row => row.processName,
            sortable: true
        },
        {
            name: "Date",
            selector: row => new Date(row.creationTime).toLocaleString(),
            sortable: true
        },
        {
            name: "File Name",
            selector: row => {
                const filePath = row.filePath;
                const filename = filePath.split("\\").pop();
                return (<span title={filename}>{filename}</span>);
            },
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = rowA.filePath;
                var b = rowB.filePath;

                return Intl.Collator().compare(a, b);
            }
        },
        {
            name: "Type",
            selector: row => row.processTypeDescription,
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.processStatus,
            sortable: true
        },
        {
            name: "Actions",
            selector: row => {
                if (row.processStatusId != 6 && row.processStatusId != 7 && row.processStatusId != 8) {
                    return (<button onClick={() => openDetails(row.id, row.processTypeId)} className='btn btn-primary'><i className='fa fa-list-alt'></i></button>);
                }
            },
            sortable: false
        }
    ];

    const expandedComponentTasks = ({ data }) => {
        return (<table style={{ width: "100%" }}>
            <tbody>
                <tr style={{ backgroundColor: "#F3F3F3" }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: "20px 20px" }}>Started at: {!data.startRunTime ? "" : new Date(data.startRunTime).toLocaleString()}</th>
                </tr>
                <tr style={{ backgroundColor: "#F3F3F3" }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: "20px 20px" }}>Finished at: {!data.finishRunTime ? "" : new Date(data.finishRunTime).toLocaleString()}</th>
                </tr>
                <tr style={{ backgroundColor: "#F3F3F3" }}>
                    <th>&nbsp;</th>
                    <th style={{ padding: "20px 20px" }}>Result Message: {data.resultMessage}</th>
                </tr>
            </tbody>
        </table>);
    };

    const columnsTasks = [
        {
            name: "Name",
            selector: row => row.processName,
            sortable: true
        },
        {
            name: "Type",
            selector: row => row.processTypeDescription,
            sortable: true
        },
        {
            name: "Created at",
            selector: row => new Date(row.creationTime).toLocaleString(),
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.processStatus,
            sortable: true
        }
    ];

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: true, urlBotaoVoltar: null }));

        Promise.allSettled([fetchProcessesInfo(1, processesPerPage), fetchTasksInfo(1, tasksPerPage), fetchProcessTypes()])
            .then(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }, []);

    async function fetchProcessesInfo(page, newPerPage) {
        MySwal.close();

        setIsLoadingProcessesTable(true);

        let response = await fetch(`/api/processes?pageNumber=${page}&pageSize=${newPerPage}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${context.token}`
                }
            });

        if (response.status === 200) {
            let result = await response.json();

            if (result) {
                setProcessesInfo(result);
            }
        }

        setIsLoadingProcessesTable(false);
    }

    async function fetchProcessTypes() {
        fetch("/api/processes/types", {
            method: "GET",
            headers: { "Authorization": `Bearer ${context.token}` }
        }).then(async response => {

            let responseJson = await response.json();

            if (response.status != 200) throw responseJson.message;

            setProcessTypes(responseJson);
        }).catch((error) => {
            MySwal.fire({
                icon: "error",
                html: error
            });
        }).finally(() => {
            setContext(curr => ({ ...curr, isLoading: false }));
        });
    }

    async function fetchTasksInfo(page, newPerPage) {
        MySwal.close();

        setIsLoadingTasksTable(true);

        let response = await fetch(`/api/tasks?pageNumber=${page}&pageSize=${newPerPage}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${context.token}`
                }
            });

        if (response.status === 200) {
            let result = await response.json();

            if (result) {
                setTasksInfo(result);
            }
        }

        setIsLoadingTasksTable(false);
    }

    function openNewRun() {
        window.location.href = "/processes/new";
    }

    function openDetails(idProcess, processTypeId) {
        navigate(`/processes/details/${processTypeId}/${idProcess}`);
    }

    function chooseProcessType() {
        const html =
            <>
                <br />
                <label>Process Type:</label>
                <select ref={processTypeSelect} className="form-control">
                    <option value="">Select...</option>
                    {processTypes.map(p => <option key={p.id} value={p.id}>{p.description}</option>)}
                </select>
                <br />
            </>;


        MySwal.fire({
            title: "Download template",
            html: html,
            confirmButtonText: "Download"
        }).then(value => {
            if (value.value) {
                let processTypeId = processTypeSelect.current.value;

                if (!processTypeId) {
                    MySwal.fire({
                        html: "No process type selected",
                        icon: "error",
                    });
                    return;
                }

                downloadTemplateCsv(processTypeId);
            }
        });
    }

    function downloadTemplateCsv(processTypeId) {
        setContext(curr => ({ ...curr, isLoading: true }));

        let csvHeaders;

        if (processTypeId == 12) {
            csvHeaders = ["URL", "FIRST", "LAST", "EMAIL", "PASSWORD", "ADDRESS1", "CITY", "STATE", "ZIPCODE", "PHONE", "BIRTHMONTH", "BIRTHDAY", "BIRTHYEAR", "PROXY"];
            setCsvData([csvHeaders]);

            setTimeout(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            }, 2000);
        } else {
            csvHeaders = ["EMAIL", "PASSWORD", "FIRSTNAME", "LASTNAME", "ZIPCODE", "PHONE", "EVENT", "VFURL", "SHOW1", "SHOW2", "SHOW3", "SHOW4", "SHOW5", "BILLINGCCNAME", "BILLINGCCNUMBER",
                "BILLINGMONTH", "BILLINGYEAR", "BILLINGCVV", "BILLINGADDRESS1", "BILLINGADDRESS2", "BILLINGCITY", "BILLINGSTATE", "BILLINGZIP", "PROXY"];

            fetch("/api/users/phones", { method: "GET", headers: { "Authorization": `Bearer ${context.token}` } })
                .then(result => {
                    if (result.status == 200)
                        return result.json();
                }).then(result => {
                    if (!!result && !!result.data?.length) {
                        let finalData = result.data?.map(x => ["", "", "", "", "", x, "", "", "", "", "", "", "", ""]);
                        setCsvData([csvHeaders, ...finalData]);
                    }

                    setContext(curr => ({ ...curr, isLoading: false }));
                }).catch(() => {
                    MySwal.fire({
                        toast: true,
                        html: "Error obtaining user phone numbers",
                        icon: "error",
                        position: "top-end",
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false
                    });
                    setContext(curr => ({ ...curr, isLoading: false }));
                });
        }
    }

    function openModalKillButton() {
        MySwal.fire({
            icon: "question",
            html: "Do you want to kill all running and queued processes?",
            reverseButtons: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            confirmButtonColor: "#37b620",
            cancelButtonColor: "#e74a3b"
        }).then(value => {
            if (value.value) {
                setContext(curr => ({ ...curr, isLoading: true }));

                fetch("/api/processes", { method: "DELETE", headers: { "Authorization": `Bearer ${context.token}` } })
                    .then(result => {
                        if (result.status == 200)
                            return result.json();
                    }).then(result => {
                        if (!result || !result.success) {
                            console.error(result);
                            throw result;
                        }

                        MySwal.fire({
                            icon: "success",
                            html: "Processes successfully killed!"
                        });

                        setContext(curr => ({ ...curr, isLoading: false }));
                    }).catch(error => {
                        MySwal.fire({
                            html: "Error killing your processes",
                            icon: "error",
                        });

                        console.log(error);

                        setContext(curr => ({ ...curr, isLoading: false }));
                    });
            }
        });
    }

    async function handleProcessesPageChange(page) {
        if (!processesInfo?.processes?.length) {
            return;
        }

        await fetchProcessesInfo(page, processesPerPage);
    }

    async function handleProcessesRowsPerPageChange(newPerPage, page) {
        if (!processesInfo?.processes?.length) {
            return;
        }

        if (newPerPage != processesPerPage) {
            setProcessesPerPage(newPerPage);
        }

        await fetchProcessesInfo(page, newPerPage);
    }

    async function handleTasksPageChange(page) {
        if (!tasksInfo?.tasks?.length) {
            return;
        }

        await fetchTasksInfo(page, tasksPerPage);
    }

    async function handleTasksRowsPerPageChange(newPerPage, page) {
        if (!tasksInfo?.tasks?.length) {
            return;
        }

        if (newPerPage != tasksPerPage) {
            setTasksPerPage(newPerPage);
        }

        await fetchTasksInfo(page, newPerPage);
    }

    useEffect(() => {
        if (csvData.length > 0) {
            csvButton.current.link.click();
        }
    }, [csvData]);

    return (
        <>

            <div className="d-sm-flex align-items-center mb-4" style={{ justifyContent: "space-between" }}>
                <div className="d-sm-flex align-items-center mb-4">
                    <i className="fa fa-network-wired"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Processes</h1>
                </div>

                <div className='d-sm-flex justify-content-end align-items-center mb-4'>
                    <button className='btn btn-warning mr-2' onClick={() => chooseProcessType()}>Download template</button>
                    <CSVLink
                        style={{ display: "none" }}
                        separator=","
                        ref={csvButton}
                        data={csvData}
                        filename={"tixtext-template.csv"}
                        enclosingCharacter={""}
                    >Download template</CSVLink>
                    <button className='btn btn-primary mr-2' onClick={() => openNewRun()}>Create New Process</button>
                    <button className='btn btn-danger mr-2' onClick={() => openModalKillButton()}>Kill Switch</button>
                </div>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={columnsProcesses}
                                    data={processesInfo.processes}
                                    progressPending={isLoadingProcessesTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    pagination
                                    paginationServer
                                    noDataComponent={<>No rows found</>}
                                    paginationTotalRows={processesInfo.totalRows}
                                    onChangeRowsPerPage={handleProcessesRowsPerPageChange}
                                    onChangePage={handleProcessesPageChange}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationComponentOptions={{ rowsPerPageText: "Lines per page", rangeSeparatorText: "of" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-network-wired"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Tasks</h1>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={columnsTasks}
                                    data={tasksInfo.tasks}
                                    expandableRows
                                    expandableRowsComponent={expandedComponentTasks}
                                    progressPending={isLoadingTasksTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    pagination
                                    paginationServer
                                    noDataComponent={<>No rows found</>}
                                    paginationTotalRows={tasksInfo.totalRows}
                                    onChangeRowsPerPage={handleTasksRowsPerPageChange}
                                    onChangePage={handleTasksPageChange}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationComponentOptions={{ rowsPerPageText: "Lines per page", rangeSeparatorText: "of" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
