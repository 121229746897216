import React, { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);

export default function NewProcess() {
    const { context, setContext } = useContext(AppContext);

    const [csvFile, setCsvFile] = useState(null);
    const [processName, setProcessName] = useState("");
    const [processType, setProcessType] = useState(null);
    const [processTypes, setProcessTypes] = useState([]);

    const inputCsvFile = useRef(null);

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: true }));

        if (csvFile) {
            let error = "";

            if (!processName) error = "ProcessName is required";
            if (!processType) error = "ProcessType is required";

            if (error) {
                MySwal.fire({
                    toast: true,
                    html: error,
                    icon: "error",
                    position: "top-end",
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                return;
            }

            const formData = new FormData();
            formData.append("file", csvFile);

            fetch("/api/processes/upload", {
                method: "POST",
                headers: { "Authorization": `Bearer ${context.token}`, "ProcessName": processName, "ProcessTypeId": processType },
                body: formData,
            })
                .then(response => response.json())
                .then(response => {
                    if (!response.success)
                        throw response.message;

                    Swal.fire({
                        html: "File imported successfully",
                        icon: "success"
                    }).then(() => {
                        setContext(curr => ({ ...curr, isLoading: true }));
                        window.location.href = "/processes";
                    });
                }).catch((error) => {
                    MySwal.fire({
                        icon: "error",
                        html: error
                    });
                }).finally(() => {
                    setContext(curr => ({ ...curr, isLoading: false }));
                });
        }

    }, [csvFile]);

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: true, urlBotaoVoltar: null }));

        fetch("/api/processes/types", {
            method: "GET",
            headers: { "Authorization": `Bearer ${context.token}` }
        }).then(async response => {
            let responseJson = await response.json();

            if (response.status != 200) throw responseJson.message;

            setProcessTypes(responseJson);
        }).catch((error) => {
            MySwal.fire({
                icon: "error",
                html: error
            });
        }).finally(() => {
            setContext(curr => ({ ...curr, isLoading: false }));
        });
    }, []);

    function importCsvData() {

        let error = "";

        if (!processName) error = "Process Name is required";
        if (!processType) error = "Process Type is required";


        if (error) {
            MySwal.fire({
                toast: true,
                html: error,
                icon: "error",
                position: "top-end",
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false
            });
            return;
        }

        inputCsvFile.current.click();
    }

    return (
        <>
            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-network-wired"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">New Process</h1>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row" style={{ display: "flex", justifyContent: "space-around" }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body" style={{ padding: "80px 30px" }} >
                            <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-3">
                                    <label >Process Type:</label>
                                    <select className="form-control" onChange={(e) => setProcessType(e.currentTarget.value)}>
                                        <option value="">Select...</option>
                                        {processTypes.map(x => <option key={x.id} value={x.id}>{x.description}</option>)}
                                    </select>
                                </div>
                                <div className="col-sm-7">
                                    <label >Process Name:</label>
                                    <input className="form-control" type="text" onChange={(e) => setProcessName(e.currentTarget.value)} />
                                </div>
                                <div className="col-sm-1"></div>
                            </div>

                            <div className="row mt-5">
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <button className="btn btn-primary" onClick={() => importCsvData()} style={{ width: "20rem" }}>Import CSV Data</button>
                                    <input type="file" style={{ display: "none" }} ref={inputCsvFile} onChange={(e) => setCsvFile(e.target.files[0])} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}