import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);


export default function Dashboard() {
    const { context, setContext } = useContext(AppContext);

    const navigate = useNavigate();

    const [showsPerPage, setShowsPerPage] = useState(10);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [shows, setShows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const columns = [
        {
            name: "Date",
            selector: row => `${row.date ?? ""} ${row.time ?? ""}`,
            sortable: true
        },
        {
            name: "Event",
            selector: row => row.event,
            sortable: true
        },
        {
            name: "Venue",
            selector: row => row.venue,
            sortable: true
        },
        {
            name: "City",
            selector: row => row.city,
            sortable: true
        },
        {
            name: "State",
            selector: row => row.state,
            sortable: true
        },
        {
            name: "Actions",
            selector: row => <button onClick={() => openDetails(row.id)} alt="Sales" className="btn btn-success"><i className="fa fa-money-check-dollar"></i></button>,
            sortable: false
        }
    ];

    useEffect(() => {
        setContext(old => ({ ...old, urlBotaoVoltar: null, isLoading: true }));

        Promise.allSettled([fetchShows(1, showsPerPage)])
            .then(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }, []);

    async function fetchShows(page, newPerPage) {
        MySwal.close();

        setIsLoadingTable(true);

        let response = await fetch(`/api/pricehistory?pageNumber=${page}&pageSize=${newPerPage}`,
            {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${context.token}`
                }
            });

        if (response.status === 200) {
            let result = await response.json();

            if (result) {
                setShows(result.shows);
                setTotalRows(result.totalRows);
            }

        } else {
            MySwal.fire({
                icon: "error",
                html: "Error retrieving the Price History"
            });
        }

        setIsLoadingTable(false);
    }

    function openDetails(id) {
        navigate(`/pricehistory/${id}/sales`);
    }

    async function handleShowsPageChange(page) {
        if (!shows?.length) return;

        await fetchShows(page, showsPerPage);
    }

    async function handleShowsRowsPerPageChange(newPerPage, page) {
        if (!shows?.length) return;

        if (newPerPage != showsPerPage)
            setShowsPerPage(newPerPage);

        await fetchShows(page, newPerPage);
    }

    return (
        <>

            <div className="d-sm-flex align-items-center mb-4" style={{ justifyContent: "space-between" }}>
                <div className="d-sm-flex align-items-center mb-4">
                    <i className="fa fa-dollar-sign"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Price History</h1>
                </div>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={columns}
                                    data={shows}
                                    progressPending={isLoadingTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    pagination
                                    paginationServer
                                    noDataComponent={<>No rows found</>}
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handleShowsRowsPerPageChange}
                                    onChangePage={handleShowsPageChange}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationComponentOptions={{ rowsPerPageText: "Lines per page", rangeSeparatorText: "of" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
