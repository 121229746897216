import React from "react";
import { Navigate } from "react-router-dom";

export default function Protected({ context, requiredRoles, children }) {
    if (!context.token) {
        return <Navigate to="/" replace />;
    }

    if (context.profiles.includes("administrator") || !requiredRoles?.length)
        return children;

    if (requiredRoles?.length) {
        const hasRequiredRoles = requiredRoles.some((role) => context.profiles.includes(role));

        if (!hasRequiredRoles)
            return <></>;
    }

    return children;
}