import React, { useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";
import logo from "../../img/tixtext.png";
import "../../css/Login.css";

const MySwal = withReactContent(Swal);

export default function Login() {
    const [iUsername, setIUserName] = useState();
    const [password, setPassword] = useState();

    const [loginError, setLoginError] = useState(false);

    const { setContext } = useContext(AppContext);

    const handleSubmit = async e => {
        e.preventDefault();
        setContext(old => ({ ...old, isLoading: true }));

        loginUser({ username: iUsername, password })
            .then(result => {
                if (result.status != 423) {
                    return result.json();
                } else {
                    MySwal.fire({
                        icon: "error",
                        title: <>Too many attempts.<br /> Account locked.
                            <br /><br />
                            Please contact our support:
                            <br />
                            support@tixtext.com
                        </>
                    });

                    setLoginError(false);
                }
            })
            .then(result => {
                if (result) {
                    if (result.success == true) {
                        setContext(old => ({ ...old, token: result.token, username: iUsername, profiles: (result.profiles || "").split("|") }));

                        setLoginError(false);
                    } else {
                        setContext(old => ({ ...old, token: null, username: null, profiles: [] }));

                        setLoginError(result.message);
                    }
                }
            })
            .catch(() => {
                MySwal.fire({
                    icon: "error",
                    title: <>Internal Server Error. <br />If it persists, please contact our support: <br />support@tixtext.com</>
                });

                setLoginError(false);
            })
            .finally(() => {
                setContext(old => ({ ...old, isLoading: false }));
            });
    };

    const loginUser = (credentials) => {
        return fetch("/api/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(credentials)
        });
    };

    useEffect(() => {
        setContext(old => ({ ...old, urlBotaoVoltar: null }));
    });

    return (
        <>
            <div className="limiter">
                <div className="container-login100" id='bgImgCity'>
                    <div className="wrap-login100">
                        <form onSubmit={handleSubmit} className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img src={logo} style={{ width: "25vw" }} />

                            </span>

                            <span className="login100-form-title mt-3 mb-3">
                                Log in
                            </span>

                            <div style={{ display: !loginError ? "none" : "", textAlign: "center", color: "red", padding: "20px 0" }}>
                                <span>{loginError}</span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter username">
                                <input className="input100" type="text" name="username" placeholder="Username"
                                    onChange={e => setIUserName(e.target.value)} />
                                <span className="focus-input100" data-placeholder="&#xf207;"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter password">
                                <input className="input100" type="password" name="pass" placeholder="Password"
                                    onChange={e => setPassword(e.target.value)} />
                                <span className="focus-input100" data-placeholder="&#xf191;"></span>
                            </div>

                            <div className="container-login100-form-btn">
                                <button type='submit' className="login100-form-btn">
                                    Login
                                </button>
                            </div>

                        </form>
                    </div>
                </div>

                <footer className='footer-login'>
                    © TixText 2023 - All rights reserved
                </footer>
            </div>
        </>
    );
}
