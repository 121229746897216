import React, { useContext, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);

export default function ImportCsv({ apiSendFile, classListButton, classListIcon, btnText }) {
    const { context, setContext } = useContext(AppContext);

    const inputHidden = useRef();

    function sendFile(e) {

        setContext(old => ({ ...old, isLoading: true }));

        const file = e.target.files[0];

        clearInputFile(e);

        const data = new FormData();

        data.append(file.name, file, file.name);

        fetch(apiSendFile, {
            method: "POST", headers: { "Authorization": `Bearer ${context.token}` },
            body: data
        }).then(res => res.json)
            .then(() => {
                MySwal.fire({
                    icon: "success",
                    html: "File has been uploaded successfully"
                });
            })
            .catch(() => {
                MySwal.fire({
                    icon: "error",
                    html: "Error uploading the CSV file"
                });
            })
            .finally(() => {
                setContext(old => ({ ...old, isLoading: false }));
            });
    }

    function clearInputFile(e) {
        e.target.value = "";

        if (e.target.value) {
            e.target.type = "text";
            e.target.type = "file";
        }
    }

    return (
        <>
            <input type='file' hidden='hidden' ref={inputHidden} onChange={e => sendFile(e)} />

            <button className={classListButton} onClick={() => inputHidden.current.click()}>
                <i className={classListIcon}></i>{btnText}
            </button>
        </>
    );
}