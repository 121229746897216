import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { InfinitySpin } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);

export default function MailboxSms() {
    const { context, setContext } = useContext(AppContext);

    const [smsData, setSmsData] = useState({ smsMailbox: [], totalRows: 0 });
    const [phonesData, setPhonesData] = useState({ phones: [], totalRows: 0 });

    const [smsFilters, setSmsFilters] = useState({
        lineNumber: null,
        phoneNumber: null,
        sender: null,
        message: null
    });

    const [phonesFilters, setPhonesFilters] = useState({
        lineNumber: null,
        phoneNumber: null,
    });

    const [isRunningBulkRotation, setIsRunningBulkRotation] = useState(false);

    const [isLoadingSmsTable, setIsLoadingSmsTable] = useState(false);
    const [isLoadingPhonesTable, setIsLoadingPhonesTable] = useState(false);

    const [smsPerPage, setSmsPerPage] = useState(10);
    const [phonesPerPage, setPhonesPerPage] = useState(10);

    const [smsCurrentPage, setSmsCurrentPage] = useState(1);
    const [phonesCurrentPage, setPhonesCurrentPage] = useState(1);

    const smsMailboxColumns = [
        {
            name: "Line #",
            selector: row => row.lineNumber,
            grow: 1,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = parseInt(rowA.lineNumber ?? "0");
                var b = parseInt(rowB.lineNumber ?? "0");

                return Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare(a, b);
            }
        },
        {
            name: "Phone #",
            selector: row => row.receiver,
            compact: true,
            grow: 1,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = parseInt(rowA.receiver ?? "0");
                var b = parseInt(rowB.receiver ?? "0");

                return Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare(a, b);
            }
        },
        {
            name: "Sender",
            selector: row => row.sender,
            compact: true,
            grow: 1,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = parseInt(rowA.sender ?? "0");
                var b = parseInt(rowB.sender ?? "0");

                return Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare(a, b);
            }
        },
        {
            name: "Message",
            selector: row => (row.message ?? "").substr(0, 70) + "...",
            grow: 3,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = (rowA.message ?? "").substr(0, 70);
                var b = (rowB.message ?? "").substr(0, 70);

                return Intl.Collator().compare(a, b);
            }
        },
        {
            name: "Time Received",
            selector: row => !row.timeReceived ? "" : new Date(row.timeReceived)?.toLocaleString(),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = rowA.timeReceived;
                var b = rowB.timeReceived;

                return Intl.Collator().compare(a, b);
            }
        }
    ];

    const phoneNumbersColumns = [
        {
            name: "Line #",
            selector: row => row.lineNumber,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = parseInt(rowA.lineNumber ?? "0");
                var b = parseInt(rowB.lineNumber ?? "0");

                return Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare(a, b);
            }
        },
        {
            name: "Phone #",
            selector: row => row.phoneNumber,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = parseInt(rowA.phoneNumber ?? "0");
                var b = parseInt(rowB.phoneNumber ?? "0");

                return Intl.Collator(undefined, { numeric: true, sensitivity: "base" }).compare(a, b);
            }
        },
        {
            name: "Last Checked",
            selector: row => !row.lastTimeChecked ? "" : new Date(row.lastTimeChecked)?.toLocaleString(),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = rowA.lastTimeChecked;
                var b = rowB.lastTimeChecked;

                return Intl.Collator().compare(a, b);
            }
        },
        {
            name: "Check",
            selector: row => !isRunningBulkRotation ? <button className="btn btn-primary" onClick={() => startSingleRotation(row.modem, row.port, row.slot, row.id)}>Check Now</button> : <></>,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                var a = rowA.isRunningBulkRotation;
                var b = rowB.isRunningBulkRotation;

                return Intl.Collator().compare(a, b);
            }
        }
    ];

    const smsMailboxExpandedComponent = ({ data }) => {
        return (<table style={{ width: "100%" }}>
            <tbody >
                <tr style={{ backgroundColor: "#F3F3F3" }}>
                    <th style={{ padding: "20px 20px" }}><b>Full Message:</b> <span style={{ fontWeight: "200" }}>{data.message}</span></th>
                </tr>
            </tbody>
        </table >);
    };

    useEffect(() => {
        setContext(curr => ({ ...curr, isLoading: true, urlBotaoVoltar: null }));

        Promise.allSettled([fetchMailboxSms(1, smsPerPage), fetchMailboxPhones(1, phonesPerPage)])
            .then(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }, []);

    async function fetchMailboxSms(page, newPerPage) {
        setIsLoadingSmsTable(true);

        let body = {
            pagination: {
                pageSize: newPerPage,
                pageNumber: page
            },
            lineNumber: smsFilters.lineNumber,
            phoneNumber: smsFilters.phoneNumber,
            sender: smsFilters.sender,
            message: smsFilters.message
        };

        let response = await fetch("/api/ejoin/mailbox/sms",
            {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${context.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });

        if (response.status == 200) {
            let result = await response.json();

            if (result) {
                setSmsData({
                    smsMailbox: result.data?.smsMailbox,
                    totalRows: result.data?.totalRows
                });
            }
        }

        setIsLoadingSmsTable(false);
    }

    async function fetchMailboxPhones(page, newPerPage) {
        setIsLoadingPhonesTable(true);

        try {

            let body = {
                pagination: {
                    pageSize: newPerPage,
                    pageNumber: page
                },
                lineNumber: phonesFilters.lineNumber,
                phoneNumber: phonesFilters.phoneNumber,
            };

            let response = await fetch("/api/ejoin/mailbox/phones",
                {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${context.token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                });

            if (response.status == 200) {
                let result = await response.json();

                if (result) {
                    setPhonesData({
                        phones: result.data?.phones,
                        totalRows: result.data?.totalRows
                    });
                }
            }
        } finally {
            setIsLoadingPhonesTable(false);
        }
    }

    function startSingleRotation(modem, port, slot, slotId) {
        setContext(curr => ({ ...curr, isLoading: true }));

        var errorMsg = <>
            {!modem ? <><span>Modem not found for this slot</span><br /></> : <></>}
            {!port ? <><span>Port not found for this slot</span><br /></> : <></>}
            {!slot ? <><span>Slot not found for this slot</span><br /></> : <></>}
            {!slotId ? <><span>Slot Id not found for this slot</span></> : <></>}
        </>;

        if (!modem || !port || !slot || !slotId) {
            MySwal.fire({
                icon: "error",
                html: errorMsg
            });

            setContext(curr => ({ ...curr, isLoading: false }));
            return;
        }

        var request = { modem: modem.toString(), port: port.toString(), slot: slot.toString(), slotId };

        fetch("/api/ejoin/rotation/single", {
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Authorization": `Bearer ${context.token}`, "Content-Type": "application/json" }
        })
            .then(response => response.status == 200 ? response.json() : response)
            .then(response => {
                if (response.success) {
                    MySwal.fire({
                        icon: "success",
                        html: <>Successfully started checking messages on this slot. <br />It can take some minutes to finish.</>
                    });
                } else
                    throw new Error("Error starting to check messages on this slot.");
            }).catch(error => {
                Swal.fire({
                    icon: "error",
                    html: error
                });
            }).finally(() => {
                setContext(curr => ({ ...curr, isLoading: false }));
            });
    }

    function startBulkRotation() {
        MySwal.fire({
            icon: "question",
            html: <>Are you sure that want to start a [Check All] process?<br /><br /><span style={{ color: "red" }}>(It will take several minutes and will kill all current running processes.)</span></>,
            showCancelButton: true,
            cancelButtonText: "Cancel",
            cancelButtonColor: "#e74a3b",
            confirmButtonText: "Start Check All",
            confirmButtonColor: "#37b620",
            reverseButtons: true
        }).then(value => {
            if (value.value) {
                fetch("/api/ejoin/rotation/bulk", {
                    method: "POST",
                    headers: { "Authorization": `Bearer ${context.token}`, "Content-Type": "application/json" }
                }).then(response => response.status == 200 ? response.json() : response)
                    .then(response => {
                        if (response.success) {
                            MySwal.fire({
                                icon: "success",
                                html: <>Successfully started checking all messages. <br />It will take several minutes to finish.</>
                            });
                        } else if (response.message) {
                            throw new Error(response.message);
                        } else
                            throw new Error("Error starting to check messages on this slot.");
                    }).catch(error => {
                        Swal.fire({
                            icon: "error",
                            html: error
                        });
                    }).finally(() => {
                        setContext(curr => ({ ...curr, isLoading: true }));

                        refreshRunningProcesses()
                            .finally(() => {
                                setContext(curr => ({ ...curr, isLoading: false }));
                            });
                    });
            }
        });
    }

    async function refreshRunningProcesses() {
        const response = await fetch("/api/processes/running",
            { method: "GET", headers: { "Authorization": `Bearer ${context.token}` } });

        if (response.status == 200) {
            const result = await response.json();

            if (result) {
                setIsRunningBulkRotation(!!response.isRunningRotation);
            }
        }
    }

    async function handleSmsPageChange(page) {
        if (!smsData?.smsMailbox?.length) {
            return;
        }

        if (page != smsCurrentPage) {
            setSmsCurrentPage(page);
        }

        await fetchMailboxSms(page, smsPerPage);
    }

    async function handleSmsRowsPerPageChange(newPerPage, page) {
        if (!smsData?.smsMailbox?.length) {
            return;
        }

        if (newPerPage != smsPerPage) {
            setSmsPerPage(newPerPage);
        }

        await fetchMailboxSms(page, newPerPage);
    }

    async function handlePhonesPageChange(page) {
        if (!phonesData?.phones?.length) {
            return;
        }

        if (page != phonesCurrentPage) {
            setPhonesCurrentPage(page);
        }

        await fetchMailboxPhones(page, phonesPerPage);
    }

    async function handlePhonesRowsPerPageChange(newPerPage, page) {
        if (!phonesData?.phones?.length) {
            return;
        }

        if (newPerPage != phonesPerPage) {
            setPhonesPerPage(newPerPage);
        }

        await fetchMailboxPhones(page, newPerPage);
    }

    return (
        <>
            <div className="d-sm-flex align-items-center mb-4">
                <h1 className="h3 mb-0 text-gray-800">SMS Mailbox</h1>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="card shadow row" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row", padding: "20px" }}>
                    <div className="row mt-2">
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Line #:</label><br />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Phone #:</label><br />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Sender:</label><br />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Message:</label><br />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={smsFilters.lineNumber}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 25) {
                                        newValue = newValue.slice(0, 25);
                                    }

                                    setSmsFilters(old => ({ ...old, lineNumber: newValue }));
                                }}
                            />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={smsFilters.phoneNumber}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 25) {
                                        newValue = newValue.slice(0, 25);
                                    }

                                    setSmsFilters(old => ({ ...old, phoneNumber: newValue }));
                                }}
                            />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={smsFilters.sender}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 25) {
                                        newValue = newValue.slice(0, 25);
                                    }

                                    setSmsFilters(old => ({ ...old, sender: newValue }));
                                }}
                            />
                        </div>
                        <div className="col-3" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={smsFilters.message}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 100) {
                                        newValue = newValue.slice(0, 100);
                                    }

                                    setSmsFilters(old => ({ ...old, message: newValue }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2 d-flex justify-content-end">
                        <div style={{ flex: 0 }}>
                            <button className="form-control btn btn-primary" onClick={() => {
                                setSmsCurrentPage(1);
                                fetchMailboxSms(1, smsPerPage);
                            }}>Search</button>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={smsMailboxColumns}
                                    data={smsData?.smsMailbox}
                                    pagination
                                    paginationServer
                                    progressPending={isLoadingSmsTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    noDataComponent={<>No messages found</>}
                                    paginationTotalRows={smsData?.smsMailbox?.totalRows}
                                    onChangeRowsPerPage={handleSmsRowsPerPageChange}
                                    onChangePage={handleSmsPageChange}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    expandableRows
                                    expandableRowsComponent={smsMailboxExpandedComponent}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="d-sm-flex align-items-center mb-4 mt-4">
                    <h1 className="h3 mb-0 text-gray-800">Phone Numbers</h1>
                </div>

                <div className="card shadow row" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row", padding: "20px" }}>
                    <div className="row mt-2">
                        <div className="col-6" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Line #:</label><br />
                        </div>
                        <div className="col-6" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <label>Phone #:</label><br />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-6" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={phonesFilters.lineNumber}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 25) {
                                        newValue = newValue.slice(0, 25);
                                    }

                                    setPhonesFilters(old => ({ ...old, lineNumber: newValue }));
                                }}
                            />
                        </div>
                        <div className="col-6" style={{ display: "flex", justifyContent: "flex-start" }}>
                            <input type="text" className="form-control"
                                value={phonesFilters.phoneNumber}
                                onChange={(e) => {
                                    let newValue = (e.currentTarget.value ?? "").trim();

                                    if (newValue === "") {
                                        newValue = null;
                                    } else if (newValue.length > 25) {
                                        newValue = newValue.slice(0, 25);
                                    }

                                    setPhonesFilters(old => ({ ...old, phoneNumber: newValue }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                            <button style={{ flex: 0 }} className="form-control ml-2 btn btn-primary" onClick={() => {
                                setPhonesCurrentPage(1);
                                fetchMailboxPhones(1, phonesPerPage);
                            }}>Search</button>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="card shadow" style={{ display: "flex", justifyContent: "space-around", padding: "20px" }}>
                        <div className="row d-flex" style={{ paddingRight: "20px" }}>
                            <div className="col-6">
                            </div>
                            <div className="col-6 mt-2 d-flex justify-content-end">
                                {
                                    !phonesData?.phones?.length ?
                                        (<></>) :
                                        (!isRunningBulkRotation ?
                                            (<button className="btn btn-primary" onClick={() => startBulkRotation()}>Check All</button>) :
                                            (<button className="btn btn-danger" disabled="disabled">Checking All...</button>))
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    className="table table-striped table-bordered"
                                    columns={phoneNumbersColumns}
                                    pagination
                                    paginationServer
                                    progressPending={isLoadingPhonesTable}
                                    progressComponent={<InfinitySpin width="200" color="#37b620" visible={false} />}
                                    noDataComponent={<>No phones found</>}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationTotalRows={phonesData?.totalRows}
                                    onChangeRowsPerPage={handlePhonesRowsPerPageChange}
                                    onChangePage={handlePhonesPageChange}
                                    data={phonesData?.phones}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
