import React from "react";
import logo from "../../img/tixtext.png";

export default function LayoutNoLogin({ childEl }) {
    return (
        <>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <img src={logo} style={{ width: "120px" }} />
            </nav>
            <div className="container-fluid">
                {childEl}
            </div>
        </>
    );
}