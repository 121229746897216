import React, { useContext, useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppContext } from "../../contexts/AppContext";

const MySwal = withReactContent(Swal);

export default function ChangePassword() {
    const { context, setContext } = useContext(AppContext);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [minimumLengthPassed, setMinimumLengthPassed] = useState(false);
    const [specialCharacterPassed, setSpecialCharacterPassed] = useState(false);
    const [upperCasePassed, setUpperCasePassed] = useState(false);
    const [lowerCasePassed, setLowerCasePassed] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const generateRandomPassword = async () => {
        const length = 12;
        const specialChars = "!@#$%^&*()_-+=<>?";
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";

        const charSets = [specialChars, upperCaseChars, lowerCaseChars, numbers];
        const charSetCounts = [2, 2, 2, 2];

        const passwordArray = [];

        function getRandomCharacter(charSet) {
            return charSet[Math.random() * charSet.length | 0];
        }

        charSets.forEach((charSet, index) => {
            while (charSetCounts[index]--) {
                passwordArray.push(getRandomCharacter(charSet));
            }
        });

        while (passwordArray.length < length) {
            passwordArray.push(getRandomCharacter(charSets[Math.random() * charSets.length | 0]));
        }

        for (let i = passwordArray.length - 1; i > 0; i--) {
            const j = Math.random() * (i + 1) | 0;
            [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
        }

        const password = passwordArray.join("");

        setNewPassword(password);
        setConfirmNewPassword(password);

        navigator.clipboard.writeText(password);

        MySwal.fire({
            toast: true,
            html: "Password generated and copied to clipboard",
            icon: "success",
            position: "top-end",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false
        });
    };

    const typeNewPassword = (currentPassword, isConfirmPassword) => {
        setPasswordsMatch(newPassword === confirmNewPassword && !!newPassword && !!confirmNewPassword);

        if (!isConfirmPassword) {
            if (!currentPassword) {
                setMinimumLengthPassed(false);
                setSpecialCharacterPassed(false);
                setUpperCasePassed(false);
                setLowerCasePassed(false);
                return;
            }

            setMinimumLengthPassed(currentPassword.length >= 8);
            setSpecialCharacterPassed(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(currentPassword));
            setUpperCasePassed(/[A-Z]/.test(currentPassword));
            setLowerCasePassed(/[a-z]/.test(currentPassword));
        }
    };

    const savePasswordChange = () => {
        setContext(old => { return { ...old, isLoading: true }; });

        fetch("/api/auth/password/reset", {
            method: "POST",
            headers: { "Authorization": `Bearer ${context.token}`, "Content-Type": "application/json" },
            body: JSON.stringify({ oldPassword, newPassword, confirmNewPassword })
        }).then(result => {
            if (result.status == 200) {
                Swal.fire({
                    icon: "success",
                    html: "Password changed successfully!"
                });

                resetPasswordFields();

                setContext(old => { return { ...old, isLoading: false }; });

                return false;
            }

            return result.json();
        }).then(data => {
            if (data) {
                console.log(data);
                Swal.fire({
                    icon: "error",
                    html: data.message?.description
                });
                setContext(old => { return { ...old, isLoading: false }; });
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                html: err
            });

            setContext(old => { return { ...old, isLoading: false }; });
        });
    };

    const resetPasswordFields = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");

        setMinimumLengthPassed(false);
        setSpecialCharacterPassed(false);
        setUpperCasePassed(false);
        setLowerCasePassed(false);
        setPasswordsMatch(false);
    };

    useEffect(() => {
        typeNewPassword(newPassword, false);
    }, [newPassword]);

    useEffect(() => {
        typeNewPassword(confirmNewPassword, true);
    }, [confirmNewPassword]);

    useEffect(() => {
        setContext(old => ({ ...old, urlBotaoVoltar: null }));
    }, []);

    return (
        <>
            <div style={{ width: "100%", height: "80vh", display: !context.isLoading ? "none" : "flex", justifyContent: "center", alignContent: "center" }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div className="d-sm-flex align-items-center mb-4">
                <i className="fa fa-key"></i>&nbsp;&nbsp;
                <h1 className="h3 mb-0 text-gray-800">Change Password</h1>
            </div>

            <div style={{ display: "flex" }}>

                <div className='col-sm-3'>
                </div>

                <div className='col-sm-9 d-sm-flex justify-content-end align-items-center mb-4'>
                    <button onClick={() => generateRandomPassword()} className='btn btn-primary mr-2'><i className='fa fa-random'></i>&nbsp;Generate Password</button>
                </div>
            </div>

            <div style={{ display: !context.isLoading ? "" : "none" }}>
                <div className="row" style={{ display: "flex", justifyContent: "space-around" }}>
                </div>
                <div className="row">
                    <div className="card shadow" style={{ padding: "0" }}>
                        <div className="card-body">
                            <div className="row" >
                                <div className="col-sm-8">
                                    <div className="row" >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <label style={{ textAlign: "center" }}>Old Password:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: "30vw", maxWidth: "400px" }}
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.currentTarget.value)}
                                                onKeyDown={(e) => e.keyCode == 32 ? e.preventDefault() : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4" >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <label style={{ textAlign: "center" }}>New Password:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: "30vw", maxWidth: "400px" }}
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.currentTarget.value)}
                                                onKeyDown={(e) => e.keyCode == 32 ? e.preventDefault() : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4" >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            <label style={{ textAlign: "center" }}>Confirm New Password:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: "30vw", maxWidth: "400px" }}
                                                value={confirmNewPassword}
                                                onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
                                                onKeyDown={(e) => e.keyCode == 32 ? e.preventDefault() : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2" >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                            {
                                                !minimumLengthPassed || !specialCharacterPassed || !upperCasePassed || !lowerCasePassed || !passwordsMatch || !oldPassword ?
                                                    <button className="btn btn-danger" disabled>Save Changes</button> :
                                                    <button className="btn btn-primary" onClick={() => savePasswordChange()}>Save Changes</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <h5 className="mb-2">Password requirements</h5>
                                    <div className="row">
                                        <div className="col-1">
                                            <span style={{ color: !minimumLengthPassed ? "red" : "green" }}><i className={`fa fa-${!minimumLengthPassed ? "times" : "check"} mr-2`}></i></span>
                                        </div>
                                        <div className="col-10">
                                            <span style={{ color: !minimumLengthPassed ? "red" : "green" }}>Minimum length: 8</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-1">
                                            <span style={{ color: !specialCharacterPassed ? "red" : "green" }}><i className={`fa fa-${!specialCharacterPassed ? "times" : "check"} mr-2`}></i></span>
                                        </div>
                                        <div className="col-10">
                                            <span style={{ color: !specialCharacterPassed ? "red" : "green" }}>Minimum special characters: 1</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-1">
                                            <span style={{ color: !upperCasePassed ? "red" : "green" }}><i className={`fa fa-${!upperCasePassed ? "times" : "check"} mr-2`}></i></span>
                                        </div>
                                        <div className="col-10">
                                            <span style={{ color: !upperCasePassed ? "red" : "green" }}>Minimum upper case characters: 1</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-1">
                                            <span style={{ color: !lowerCasePassed ? "red" : "green" }}><i className={`fa fa-${!lowerCasePassed ? "times" : "check"} mr-2`}></i></span>
                                        </div>
                                        <div className="col-10">
                                            <span style={{ color: !lowerCasePassed ? "red" : "green" }}>Minimum lower case characters: 1</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-1">
                                            <span style={{ color: !passwordsMatch ? "red" : "green" }}><i className={`fa fa-${!passwordsMatch ? "times" : "check"} mr-2`}></i></span>
                                        </div>
                                        <div className="col-10">
                                            <span style={{ color: !passwordsMatch ? "red" : "green" }}>Passwords match</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
