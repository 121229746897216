import React, { useContext, useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import RemoteBas from "../Bas/RemoteBas";
import ChangePassword from "../ChangePassword/ChangePassword";
import Configuration from "../Configuration/Configuration";
import Layout from "../Layouts/Layout";
import LayoutNoLogin from "../Layouts/LayoutNoLogin";
import Login from "../Login/Login";
import OtpCode from "../OtpCode/OtpCode";
import DetailsProcess from "../Processes/DetailsProcess";
import NewProcess from "../Processes/NewProcess";
import Processes from "../Processes/Processes";
import Protected from "../Protected/Protected";
import Settings from "../Settings/Settings";
import MailboxSms from "../Sms/MailboxSms";
import Dashboard from "../StageFront/Dashboard";
import Ticketmaster from "../Ticketmaster/Ticketmaster";
import Users from "../Users/Users";
import Sales from "../StageFront/Sales";
import "./App.css";

function App() {
    const { context, setContext } = useContext(AppContext);

    const checkToken = () => {
        if (!context.token) {
            setContext(old => ({ ...old, isLoading: false, urlBotaoVoltar: null }));
            return;
        }

        fetch("/api/auth/session", { method: "GET", headers: { "Authorization": `Bearer ${context.token}` } })
            .then(response => {
                if (response.status != 200) {
                    setContext(old => ({ ...old, token: null, username: null, urlBotaoVoltar: null, profiles: [] }));
                }
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <div className="wrapper">

            <div style={{ width: "100%", height: "80vh", justifyContent: "center", alignContent: "center", display: context.isLoading ? "flex" : "none" }}>
                <InfinitySpin width="200" color="#37b620" visible={false} />
            </div>

            <div style={{ display: context.isLoading ? "none" : "block" }}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={!context.token ?
                            (<Login setContext={setContext} />) :
                            (<Protected context={context}> <Layout profile={context.profiles} /></Protected>)
                        } />

                        <Route path="/email/otpcode" element={
                            <Protected context={context} >
                                <LayoutNoLogin childEl={<OtpCode />} />
                            </Protected>
                        } />

                        <Route path="/ticketmaster" element={
                            <Protected context={context} requiredRoles={["stagefront", "administrator"]}> <Layout childEl={<Ticketmaster />} /></Protected>
                        } />

                        <Route path="/bas" element={
                            <Protected context={context} requiredRoles={["administrator"]}> <Layout childEl={<RemoteBas />} /></Protected>
                        } />

                        {/* =============> SLOTS ROTATION PAGE (disabled because it rotates all the slots, and now we are working with slots rotation of user owned slots) <================== 
                            <Route path="/slots" element={
                                <Protected context={context} requiredRoles={['administrator']}> <Layout childEl={<SlotsSwitcher />} /></Protected>
                            } />
                        */}
                        <Route path="/processes" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<Processes />} /></Protected>
                        } />

                        <Route path="/password/reset" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<ChangePassword />} /></Protected>
                        } />

                        <Route path="/processes/new" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<NewProcess />} /></Protected>
                        } />

                        <Route path="/processes/details/:processTypeId/:processId" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<DetailsProcess />} /></Protected>
                        } />

                        <Route path="/processes/details/" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<DetailsProcess />} /></Protected>
                        } />

                        <Route path="/sms/mailbox" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<MailboxSms />} /></Protected>
                        } />

                        <Route path="/users" element={
                            <Protected context={context} requiredRoles={["administrator"]}> <Layout childEl={<Users />} /></Protected>
                        } />

                        <Route path="/configuration" element={
                            <Protected context={context} requiredRoles={["administrator"]}> <Layout childEl={<Configuration />} /></Protected>
                        } />

                        <Route path="/settings" element={
                            <Protected context={context} requiredRoles={["external", "administrator"]}> <Layout childEl={<Settings />} /></Protected>
                        } />

                        <Route path="/pricehistory" element={
                            <Protected context={context} requiredRoles={["administrator"]}> <Layout childEl={<Dashboard />} /></Protected>
                        } />

                        <Route path="/pricehistory/:id/sales" element={
                            <Protected context={context} requiredRoles={["administrator"]}> <Layout childEl={<Sales />} /></Protected>
                        } />
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;